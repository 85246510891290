import React, { useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  makeStyles,
  FormControl,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { Context } from "../../store/store";
import AppSnackbar from "../snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  textfield: {
    width: "100%",
    color: '#FFF !important',
    borderColor: "#FFF"
  },
  paper: {
    width: "100%",
    maxWidth: "450px",
    minHeight: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Raleway', sans-serif !important",
    padding: "0 40px",
    margin: 0,
    color: '#FFF'
  },
  form: {
    display: "flex",
    width: "100%",
    minHeight: "450px",
    alignItems: "flex-start",
    justifyContent: "space-between",
    fontFamily: "'Raleway', sans-serif !important",
    color: '#FFF !important'
  },
  formHidden: {
    display: "none",
  },
  container: {
    margin: "0",
    width: "100%",
    padding: "40px",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "'Raleway', sans-serif",
  },
  button: {
    color: "#FFF",
    fontSize: "1.1rem",
    height: "60px",
    minWidth: "175px",
    backgroundColor: "#436B0B",
    "&:hover": {
      backgroundColor: "#5B910F",
      color: "#FFF"
    },
  },
  subTitle: {
    fontWeight: 500,
  },
}));

export default function AppForm({ style }) {
  const recaptchaRef = React.createRef();
  const classes = useStyles();
  const [value, setValue] = React.useState("Controlled");
  const [formValue, setFormValue] = React.useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // @ts-ignore
  const [, dispatch] = useContext(Context);
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar({
      open: false,
      message: "",
      severity: "",
    });
  };

  const updateLoader = () => {
    dispatch({
      type: "TOGGLE_LOADER",
    });
  };

  const formHandler = async (event) => {
    updateLoader();
    let resp;
    const recaptchaValue = recaptchaRef.current.getValue();

    const formData = new FormData(event.target);
    event.preventDefault();

    let obj = {};

    for (let [key, value] of formData.entries()) {
      obj = {
        ...obj,
        [key]: value,
      };
    }

    try {
      resp = await fetch(
            "https://us-central1-website-988b7.cloudfunctions.net/api/send",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...obj,
              recaptchaValue,
            }),
          }
        )
      
      // undo the loader
      updateLoader();
    } catch (err) {
      updateLoader();

      return setSnackBar({
        open: true,
        message: `Er is iets fout gegaan!: ${err}`,
        severity: "error",
      });
    }

    if (resp?.status === 204 || resp?.status === 202 || resp?.status === 200 || resp?.ok) {
      setFormValue(true);

      window.dataLayer.push({
        event: 'form.send'
      })

      return setSnackBar({
        open: true,
        message: "Succesvol verzonden!",
        severity: "success",
      });
    }

    return setSnackBar({
      open: true,
      message: "Er is iets fout gegaan!",
      severity: "error",
    });
  };

  return formValue ? (
    <Typography gutterBottom variant="h5" component="h2">
      Mail verzonden! Wij proberen zo spoedig mogelijk te reageren.
    </Typography>
  ) : (
    <form
      id="contact-form"
      style={style}
      onSubmit={formHandler}
      className={classes.form}
    >
      <FormControl className={classes.form}>
        <TextField
          id="naam"
          label="Naam"
          name="naam"
          multiline
          variant="outlined"
          onChange={handleChange}
          className={classes.textfield}
          required
        />
        <TextField
          id="email"
          label="Email"
          name="email"
          multiline
          variant="outlined"
          onChange={handleChange}
          className={classes.textfield}
          required
        />
        <TextField
          id="message"
          name="message"
          label="Bericht"
          multiline
          rows={5}
          variant="outlined"
          onChange={handleChange}
          className={classes.textfield}
          required
          color="#FFF"
          
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Le2Ow4aAAAAAJZ5Z-amNtwp5jAKiOGSzzRV6RXp"
          // onChange={onChange}
        />
        <Button className={classes.button} type="submit">
          Verzenden
        </Button>
        {snackBar.open ? (
          <AppSnackbar
            open={snackBar.open}
            message={snackBar.message}
            severity={snackBar.severity}
            handler={handleClose}
          />
        ) : (
          ""
        )}
      </FormControl>
    </form>
  );
}
