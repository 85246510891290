import React from "react";
import { Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import AppTextArea from "./components/text-area";
import AppHeader from "./components/header";
import TopCallToAction from "../../framework/top-call-to-action";
import withRoot from "../../theme/withRoot";

function AppHome() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Begin je leven met Jezus en leef als discipelen in Arnhem</title>
        <meta
          name="description"
          content="Wees discipel in Arnhem en leef het leven zoals Jezus het ons heeft voorgedaan in de Evangelieen. Wandel als gemeente door de Geest en leef zoals de eerste Christenen uit het boek Handelingen."
        />
        <meta
          property="og:description"
          content="Wees discipel in Arnhem en leef het leven zoals Jezus het ons heeft voorgedaan in de Evangelieen. Wandel als gemeente door de Geest en leef zoals de eerste Christenen uit het boek Handelingen."
        />
        <meta
          property="og:title"
          content="Begin je leven met Jezus en leef als discipelen in Arnhem"
        />
        <meta property="og:url" content={"https://begin-opnieuw.nl"} />
      </Helmet>
      <AppHeader />
      <Divider />
      <TopCallToAction />
      <Divider />
      <AppTextArea />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppHome);
