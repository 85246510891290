import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import AppHeader from "./components/header";
import AppText from "./components/text";
import BreadCrumbs from "../../framework/breadcrumb";

export default function AppReview() {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Begin Opnieuw met Jezus | 404 not found
        </title>
        <meta
          name="description"
          content="Helaas, deze pagina hebben wij niet kunnen vinden!"
        />
        <meta
          property="og:description"
          content="Helaas, deze pagina hebben wij niet kunnen vinden!"
        />
        <meta
          property="og:title"
          content="Begin Opnieuw met Jezus | 404 not found"
        />
        <meta property="og:url" content={"https://begin-opnieuw.nl/404"} />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <BreadCrumbs backgroundColor="#FFF1E3" current="404 niet gevonden" />
      <Divider />
      <AppText />
      <Divider />
    </React.Fragment>
  );
}
