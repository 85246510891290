import React from "react";
import AppTextImage from "../../../../components/app-text-image";
import AppForm from "../../../../framework/form";

export default function TextArea() {
  return (
    <AppTextImage
      title="Contact opnemen"
      text={`Neem contact met ons op door te bellen/mailen, of door onderstaand formulier in te vullen.\n\n* Mobielnr: [06 11770007](tel:0611770007).\n\n
      `}
      backgroundColor="#FFF"
    >
      <AppForm />
    </AppTextImage>
  );
}
