import React, { useContext } from "react";
import { navigate } from "hookrouter";
import {
  createStyles,
  makeStyles,
  List,
  Divider,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from "@material-ui/core";
import { Context } from "../../store/store";

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    drawerLink: {
      display: "flex",
      borderBottom: ".5px solid rgba(50, 50, 50, .2)",
      textDecoration: "none",
      fontFamily: '"Raleway", sans-serif',
    },
    drawerText: {
      display: "flex",
      justifyContent: "center",
      color: "#333",
    },
  })
);

export default function AppDrawer() {
  // useRoutes(routes)

  // @ts-ignore
  const [state, dispatch] = useContext(Context);

  const updateDrawer = () => {
    dispatch({
      type: "TOGGLE_DRAWER",
    });
  };

  const classes = useStyles();

  const sideList = (
    <div className={classes.list}>
      <Divider />
      <List>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => navigate("/")}
        >
          <ListItemText primary={"Home"} className={classes.drawerText} />
        </ListItem>
        {/* <ListItem
          button
          className={classes.drawerLink}
          onClick={() => navigate("/over-ons")}
        >
          <ListItemText primary={"Over ons"} className={classes.drawerText} />
        </ListItem> */}
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => navigate("/contact")}
        >
          <ListItemText primary={"Contact"} className={classes.drawerText} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        open={state.drawer}
        onClose={updateDrawer}
        onOpen={updateDrawer}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={updateDrawer}
          onKeyDown={updateDrawer}
        >
          {sideList}
        </div>
      </SwipeableDrawer>
    </div>
  );
}
