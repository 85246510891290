import React from "react";
import { Grid, createStyles, makeStyles, Typography } from "@material-ui/core";
import classnames from 'classnames'

const useStyles = makeStyles((theme) =>
  createStyles({
    colorWhite: {
      color: "#fff",
    },
    container: {
      minHeight: "100vh",
      margin: "-65px 0 0",
      width: "100%",
      backgroundImage: `url(${require(`../../../../assets/arnhem.jpg`)})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "center",
      [theme.breakpoints.down("sm")]: {
        minHeight: "50vh",
        backgroundAttachment: "inherit",
      },
    },
    content: {
      alignItems: "center",
      display: "flex",
      textAlign: "left",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "750px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
        maxWidth: "100%",
        textAlign: "center",
      },
    },
    title: {
      color: "#FFF",
      fontFamily: "'Raleway', sans-serif;",
      fontWeight: 700,
      textTransform: "uppercase",
      fontSize: "4rem",
      textShadow: '-5px 3px black',
      [theme.breakpoints.up("lg")]: {
        fontSize: "6rem",
      },
    },
    subTitle: {
      color: "#FFF",
      fontFamily: "'Raleway', sans-serif;",
      fontSize: "1rem",
      fontSize: "22px",
      textShadow: '2px -2px black',
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    hidden: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    }
  })
);

export default function Header() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={10} className={classes.container} justify="center">
        <Grid item xs={12} sm={12} md={6} className={classes.content}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            Wees een discipel van Jezus in Arnhem
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            gutterBottom
            className={classes.subTitle}
          >
            We moeten onszelf onderzoeken want als Christus niet in ons is, dan zullen we het niet gaan redden. We moeten lezen wat het Woord zegt over geloof en het leven met Christus en ons leven daarmee vergelijken. Als ons leven daar niet mee overeenstemt, dan zullen we ons moeten bekeren en opnieuw gaan wandelen in geloof.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classnames(classes.content, classes.hidden)}>
          <iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/zka4DUYeJ5g?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
