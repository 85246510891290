import React from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  Paper,
  Typography,
  Avatar,
} from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import AppButton from "../../components/app-button";
import classNames from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    paperContainer: {
      background: "#6bab11",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
      padding: "40px 0",
      fontFamily: "'Raleway', sans-serif",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        textAlign: "center",
      },
    },
    container: {
      margin: "0",
      width: "100%",
      background: "#c5deaa",
      padding: "120px 0",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    content: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "0 !important",
    },
    icon: {
      width: "5rem",
      height: "5rem",
      color: "#436B0B",
    },
    avatar: {
      width: "8rem",
      height: "8rem",
      backgroundColor: "#FFF1E3",
    },
    flex: {
      display: "flex",
    },
    contentText: {
      color: "#FFF1E3",
      fontWeight: 500,
      fontSize: '1.2rem',
      [theme.breakpoints.down("sm")]: {
        padding: "15px 0 0",
      },
    },
    fontWeight700: {
      fontWeight: 700,
    },
  })
);

export default function ComponentTopBanner({ backgroundColor }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ backgroundColor: backgroundColor }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        xl={8}
        justify="center"
        alignItems="center"
      >
        <Paper className={classes.paperContainer}>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            justify="center"
            alignItems="center"
            className={classes.flex}
          >
            <Avatar
              className={classes.avatar}
              alt="plan een ontmoeting met een mede discipel."
            >
              <EventAvailableIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={6}
            justify="center"
            alignItems="start"
            className={classes.flex}
            direction="column"
          >
            <Typography
              component="h2"
              variant="h6"
              className={classNames(classes.contentText, classes.fontWeight700)}
            >
              Neem contact op
            </Typography>
            <Typography
              component="h3"
              variant="subtitle1"
              className={classes.contentText}
            >
              Als je verlangt om te leren om actief te zijn zodat je kan worden gebruikt in het Koninkrijk van de levende God neem dan contact met ons op. Wij gaan dan samen met jou in Arnhem e.om. voor zieken bidden, demonen uitdrijven en het Evangelie te verkondigen!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            justify="center"
            alignItems="center"
            className={classes.flex}
          >
            <AppButton href="/contact">Meer info</AppButton>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
