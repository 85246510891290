import React from "react";
import AppHeader from "../../../../components/app-hero-header";

export default function () {
  return (
    <AppHeader
      subTitle="not found"
      title="404"
      background="repentance.jpg"
    />
  );
}
