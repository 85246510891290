import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import AppHeader from "./components/header";
import AppText from "./components/text";
import TopCallToAction from "../../framework/top-call-to-action";
import BreadCrumbs from "../../framework/breadcrumb";

export default function AppContact() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Neem contact op met discipelen van Jezus in Arnhem</title>
        <meta
          name="description"
          content="Wil je in Arnhem gekickstart worden? Heb je gebed nodig? Wil je je laten dopen? Neem contact met ons op en stel je vraag."
        />
        <meta
          property="og:description"
          content="Wil je in Arnhem gekickstart worden? Heb je gebed nodig? Wil je je laten dopen? Neem contact met ons op en stel je vraag."
        />
        <meta
          property="og:title"
          content="Neem contact op met discipelen van Jezus in Arnhem"
        />
        <meta property="og:url" content={"https://begin-opnieuw.nl/contact"} />
      </Helmet>
      <Divider />
      <AppHeader />
      <Divider />
      <BreadCrumbs backgroundColor="#c5deaa" />
      <Divider />
      <AppText />
      <Divider />
      <TopCallToAction backgroundColor="#fff" />
      <Divider />
    </React.Fragment>
  );
}
