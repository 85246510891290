import React, { useEffect } from "react";
import { useRoutes, useInterceptor } from "hookrouter";
import AppHome from "../pages/home";
import AppContact from "../pages/contact";
import Page404 from "../pages/404";

const routes = {
  "/": () => <AppHome />,
  "/contact": () => <AppContact />,
};

const App = () => {
  useInterceptor((currentPath, nextPath) => {
    window.scrollTo(0, 0);
    window.dataLayer.push({
      event: "pageview",
    });
    return nextPath;
  });

  const routeResult = useRoutes(routes);
  return routeResult || <Page404 />;
};

export default App;
