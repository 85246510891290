import React from "react";
import {
  createStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Grid, Link, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    anchor: {
      display: "flex",
      flexDirection: "column",
      fontSize: "1rem",
      lineHeight: 2,
      fontFamily: '"Raleway", sans-serif',
      fontWeight: 500,
    },
    container: {
      background: "#c5deaa",
      margin: "0",
      width: "100%",
      padding: "40px",
      [theme.breakpoints.down("sm")]: {
        padding: "40px 0",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "40px",
    },
    image: {
      margin: "35px 0",
      width: "100%",
    },
    margin: {
      margin: theme.spacing(1),
    },
    title: {
      fontSize: "1rem !important",
      fontFamily: '"Raleway", sans-serif',
      fontWeight: 500,
      marginBottom: "1.5rem",
    },
    text: {
      fontSize: "1rem",
      lineHeight: 1.2,
      fontFamily: '"Raleway", sans-serif',
      fontWeight: 500,
      textAlign: "justify",
    },
    backgroundMain: {
      padding: "0",
      textAlign: "center",
      background: "#6bab11",
    },
    colorLight: {
      color: "#FFF",
      fontWeight: 700,
    },
  })
);

export default function Footer() {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <Grid container className={classes.container} justify="space-around">
        <Grid item xs={12} sm={12} md={4} lg={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
           Waartoe weten wij ons geroepen
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.text}>
          Wij zijn discipelen van Jezus uit Arnhem en geloven dat Jezus ons opdroeg om te wandelen zoals Hij deed. Het boek Handelingen laat zien hoe de Apostelen dit invulde. Wij geloven dat Jezus toen dezelfde is als vandaag, en wij als zijn volgelingen dus ook hetzelfde horen te wandelen als zijn volgelingen destijds! Dat betekent dat wij zijn geroepn om zieken te genezen, demonen uit te drijven en het Evangelie te verkondigen.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            Contact
          </Typography>
          <Typography variant="body1" className={classes.anchor}>
            <Link href="tel:06 11770007" target="_blank" color="inherit">
              06 11770007
            </Link>
          </Typography>
          <Typography variant="body1" className={classes.anchor}>
            <Link href="/contact" color="inherit">
              Stuur een bericht
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} className={classes.content}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            Teachings
          </Typography>
          <Typography variant="body1" className={classes.anchor}>
            <Link href="https://kingdom-focus.com" target="_blank" color="inherit">
              Kingdom Focus
            </Link>
            <Link href="https://reformatie.nu" target="_blank" color="inherit">
              De Laatste Reformatie
            </Link>
            <Link href="https://thelastreformation.com" target="_blank" color="inherit">
             The Last Reformation
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classNames([classes.container, classes.backgroundMain])}
        justify="space-around"
      >
        <Grid item xs={12} className={classes.content}>
          <Typography variant="body1" className={classes.anchor}>
            <Link
              href="//datails.nl"
              target="_blank"
              color="inherit"
              className={classes.colorLight}
            >
              Powered By DATAILS.
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
