import React from "react";
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      backgroundColor="#FFF"
      title="Onze huisgemeente in Arnhem"
      reverse
      text={`Wij geloven dat Jezus niet is gekomen om tempels en grote kerkgebouwen te stichten, maar is gekomen om zijn gemeente te maken van mensen. In Nederland zien wij dat in veel kerken het draait om traditie en religie. Jezus is niet gekomen om een nieuwe religie te prediken, maar om de Weg te laten zien om tot God te komen.\n\nWij zijn een kleine, levendige huisgemeente in Arnhem. Het doel van onze huisgemeente is dat we worden getraind om discipelen te maken zodat we vervolgens nieuwe huisgemeentes kunnen starten.\n\n### Indeling van de avonden\n\n**1. Look back:** Samen eten en terugkijken naar afgelopen week, waarin we met elkaar getuigenissen delen en de week doorpraten; liep je ergens tegenaan, heb je iets meegemaakt met God, heb je een bijzonder getuigenis etc.\n\n**2. Look up:** Elke week bereidt iemand iets voor. Deze teachings zijn zeer praktisch en we bespreken hoe het toepasbaar is op ons eigen leven. Voorbeeld vragen waarover we het hebben gehad zijn: *"Hoe zou jij je kunnen inspannen zodat over een week jou dagelijkse wandel meer lijkt op die van Jezus, dan dat ie deze week leek?" Stel je sterft vannacht, en je komt voor Jezus te staan, en Hij vraagt jou: “Heb je alles gedaan wat in je mogelijkheden lag, om Mijn wil te doen?”. Heb je je werkelijk ingespannen voor je redding?* Het is belangrijk dat we elke week opnieuw onszelf onderzoeken, opbouwen en bemoedigen zodat we effectief kunnen zijn in het Koninkrijk van God.\n\n**3. Look forward:** Wat ga je aankomende week doen om Jezus te dienen. Wie legt God op je hart om het Evangelie mee te delen, of voor wie mag je bidden of tot zegen zijn.`}
      image={require("../../../../assets/repentance.jpg")}
    >
    </AppTextImage>
  );
}
